import React, { useState } from 'react';

const BusinessOrders = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filter, setFilter] = useState('All'); // Default filter
  const [orders, setOrders] = useState([
    { id: 1, name: 'Order 1', status: 'Uploaded' },
    { id: 2, name: 'Order 2', status: 'Under Printing' },
    { id: 3, name: 'Order 3', status: 'Under Packing' },
    { id: 4, name: 'Order 4', status: 'Dispatched' },
    { id: 5, name: 'Order 5', status: 'Canceled' },
  ]);

  // Filter orders based on search and filter status
  const filteredOrders = orders
    .filter(order => 
      order.name.toLowerCase().includes(searchTerm.toLowerCase()) || 
      order.id.toString().includes(searchTerm)
    )
    .filter(order => filter === 'All' || order.status === filter);

  return (
    <div className="p-8">
      <h1 className="text-3xl font-semibold mb-6">Business Orders</h1>

      {/* Search Bar */}
      <div className="mb-6">
        <input
          type="text"
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
          placeholder="Search by Order ID or Name"
          className="px-4 py-2 border border-gray-300 rounded-lg w-full"
        />
      </div>

      {/* Filters */}
      <div className="mb-6 flex space-x-4">
        {['All', 'Uploaded', 'Under Printing', 'Under Packing', 'Dispatched', 'Canceled'].map((status) => (
          <button
            key={status}
            onClick={() => setFilter(status)}
            className={`px-4 py-2 rounded-lg border ${
              filter === status ? 'bg-blue-500 text-white' : 'bg-white text-gray-600'
            }`}
          >
            {status}
          </button>
        ))}
      </div>

      {/* Orders Table */}
      <table className="min-w-full bg-white border border-gray-200 rounded-lg overflow-hidden">
        <thead>
          <tr>
            <th className="py-3 px-4 text-left border-b">Order ID</th>
            <th className="py-3 px-4 text-left border-b">Order Name</th>
            <th className="py-3 px-4 text-left border-b">Status</th>
            <th className="py-3 px-4 text-left border-b">Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredOrders.length > 0 ? (
            filteredOrders.map(order => (
              <tr key={order.id} className="hover:bg-gray-100">
                <td className="py-3 px-4 border-b">{order.id}</td>
                <td className="py-3 px-4 border-b">{order.name}</td>
                <td className="py-3 px-4 border-b">{order.status}</td>
                <td className="py-3 px-4 border-b">
                  <button
                    onClick={() => alert(`Details for Order ${order.id}`)}
                    className="text-blue-500 hover:underline"
                  >
                    View Details
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4" className="py-3 px-4 text-center">
                No orders found.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default BusinessOrders;
