import React, { useState, useEffect } from 'react';
import axios from 'axios';

function AddProduct() {
  const [product, setProduct] = useState({
    name: '',
    price: '',
    description: '',
    shape: '',
    category: '',
    subCategory: '',
    expectedDeliveryTime: '',
    minQuantity: 1,
    isDieShape: false,
    videoUrl: '', // Add video URL field
    priceTiers: [{ quantity: 1, price: '' }], // Initialize with one price tier
  });

  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [images, setImages] = useState([]);

  useEffect(() => {
    // Fetch categories from the backend
    axios.get('https://api.payasvinimilk.com/api/categories/getCategories')
      .then(response => {
        setCategories(response.data);
      })
      .catch(error => {
        console.error('Error fetching categories:', error);
      });
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setProduct({
      ...product,
      [name]: type === 'checkbox' ? checked : value // Handle checkbox for isDieShape
    });

    // If category is selected, update the subcategories
    if (name === 'category') {
      const selectedCategory = categories.find(category => category._id === value);
      setSubCategories(selectedCategory ? selectedCategory.subcategory : []);
      setProduct(prevProduct => ({
        ...prevProduct,
        subCategory: '' // Reset subCategory on category change
      }));
    }
  };

  const handleImageChange = (e) => {
    setImages(Array.from(e.target.files));
  };

  // Handle change for price tiers
  const handlePriceTierChange = (index, e) => {
    const { name, value } = e.target;
    const newPriceTiers = [...product.priceTiers];
    newPriceTiers[index][name] = value;
    setProduct({ ...product, priceTiers: newPriceTiers });
  };

  // Add a new price tier
  const addPriceTier = () => {
    setProduct({ ...product, priceTiers: [...product.priceTiers, { quantity: 1, price: '' }] });
  };

  // Remove a price tier
  const removePriceTier = (index) => {
    const newPriceTiers = product.priceTiers.filter((_, i) => i !== index);
    setProduct({ ...product, priceTiers: newPriceTiers });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Create a FormData object
    const formData = new FormData();
    formData.append('name', product.name);
    formData.append('price', product.price);
    formData.append('description', product.description);
    formData.append('shape', product.shape);
    formData.append('category', product.category);
    formData.append('subCategory', product.subCategory);
    formData.append('expectedDeliveryTime', product.expectedDeliveryTime);
    formData.append('minimumQuantity', product.minQuantity);
    formData.append('isDieShape', product.isDieShape); // Include isDieShape in form submission
    formData.append('priceTiers', JSON.stringify(product.priceTiers));
    formData.append('videoUrl', product.videoUrl); 
    images.forEach((image) => {
      formData.append('images', image);
    });

    try {
      const response = await axios.post('https://api.payasvinimilk.com/api/products/createProduct', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      alert('Product added successfully');
      setProduct({
        name: '',
        price: '',
        description: '',
        shape: '',
        category: '',
        subCategory: '',
        expectedDeliveryTime: '',
        minQuantity: 1,
        isDieShape: false, // Reset isDieShape on form submit
        priceTiers : [{ quantity: '', price: '' }]
      });
      setImages([]);
    
    } catch (error) {
      console.error('Error adding product:', error);
    }
  };


  return (
    <div className="min-h-screen max-w-full flex justify-center items-center p-4">
      <div className="bg-white shadow-lg rounded-lg p-8 w-full max-w-full">
        <h2 className="text-2xl font-bold text-gray-800 mb-6">Add Product</h2>
        <form onSubmit={handleSubmit} className="space-y-6">
          {/* Product Name */}
          <div>
            <label className="block text-gray-700 mb-2" htmlFor="name">Product Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={product.name}
              onChange={handleChange}
              className="w-full p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-gray-800"
              placeholder="Enter product name"
            />
          </div>

          {/* Price */}
          <div>
            <label className="block text-gray-700 mb-2" htmlFor="price">Price</label>
            <input
              type="number"
              id="price"
              name="price"
              value={product.price}
              onChange={handleChange}
              className="w-full p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-gray-800"
              placeholder="Enter price"
            />
          </div>

          {/* Description */}
          <div>
            <label className="block text-gray-700 mb-2" htmlFor="description">Description</label>
            <textarea
              id="description"
              name="description"
              value={product.description}
              onChange={handleChange}
              className="w-full p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-gray-800"
              placeholder="Enter product description"
            />
          </div>

          {/* Shape */}
          <div>
            <label className="block text-gray-700 mb-2" htmlFor="shape">Shape</label>
            <select
              id="shape"
              name="shape"
              value={product.shape}
              onChange={handleChange}
              className="w-full p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-gray-800"
            >
              <option value="">Select shape</option>
              <option value="Rounded">Rounded</option>
              <option value="Square">Square</option>
              <option value="Both">Both</option>
            </select>
          </div>

          {/* Category */}
          <div>
            <label className="block text-gray-700 mb-2" htmlFor="category">Category</label>
            <select
              id="category"
              name="category"
              value={product.category}
              onChange={handleChange}
              className="w-full p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-gray-800"
            >
              <option value="">Select category</option>
              {categories.map(category => (
                <option key={category._id} value={category._id}>{category.name}</option>
              ))}
            </select>
          </div>

          {/* Subcategory */}
          <div>
            <label className="block text-gray-700 mb-2" htmlFor="subCategory">Subcategory</label>
            <select
              id="subCategory"
              name="subCategory"
              value={product.subCategory}
              onChange={handleChange}
              className="w-full p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-gray-800"
              disabled={!subCategories.length}
            >
              <option value="">Select subcategory</option>
              {subCategories.map(subCategory => (
                <option key={subCategory._id} value={subCategory._id}>{subCategory.name}</option>
              ))}
            </select>
          </div>

          {/* Expected Delivery Time */}
          <div>
            <label className="block text-gray-700 mb-2" htmlFor="expectedDeliveryTime">Expected Delivery Time</label>
            <input
              type="text"
              id="expectedDeliveryTime"
              name="expectedDeliveryTime"
              value={product.expectedDeliveryTime}
              onChange={handleChange}
              className="w-full p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-gray-800"
              placeholder="Enter expected delivery time"
            />
          </div>

          {/* Minimum Quantity */}
          <div>
            <label className="block text-gray-700 mb-2" htmlFor="minQuantity">Minimum Quantity</label>
            <input
              type="number"
              id="minQuantity"
              name="minQuantity"
              value={product.minQuantity}
              onChange={handleChange}
              className="w-full p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-gray-800"
              placeholder="Enter minimum quantity"
            />
          </div>

          {/* Price Tiers */}
          <div>
            <label className="block text-gray-700 mb-2">Price Tiers</label>
            {product.priceTiers.map((tier, index) => (
              <div key={index} className="flex space-x-4 mb-2">
                <input
                  type="number"
                  name="quantity"
                  placeholder="Quantity"
                  value={tier.quantity}
                  onChange={(e) => handlePriceTierChange(index, e)}
                  className="w-1/2 p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-gray-800"
                />
                <input
                  type="number"
                  name="price"
                  placeholder="Price"
                  value={tier.price}
                  onChange={(e) => handlePriceTierChange(index, e)}
                  className="w-1/2 p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-gray-800"
                />
                {index > 0 && (
                  <button type="button" onClick={() => removePriceTier(index)} className="bg-red-500 text-white p-2 rounded-md">
                    Remove
                  </button>
                )}
              </div>
            ))}
            <button type="button" onClick={addPriceTier} className="bg-blue-500 text-white p-2 rounded-md">
              Add Price Tier
            </button>
          </div>
          <div>
            <label className="block text-gray-700 mb-2" htmlFor="isDieShape">Is Die Shape?</label>
            <input
              type="checkbox"
              id="isDieShape"
              name="isDieShape"
              checked={product.isDieShape}
              onChange={handleChange}
              className="mr-2 leading-tight"
            />
          </div>
          {/* Images */}
          <div>
            <label className="block text-gray-700 mb-2">Product Images</label>
            <input
              type="file"
              name="images"
              multiple
              onChange={handleImageChange}
              className="w-full p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-gray-800"
            />
          </div>
          <div>
            <label className="block text-gray-700 mb-2" htmlFor="videoUrl">Video URL</label>
            <input
              type="text"
              id="videoUrl"
              name="videoUrl"
              value={product.videoUrl}
              onChange={handleChange}
              className="w-full p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-gray-800"
              placeholder="Enter video URL"
            />
          </div>
          <button
            type="submit"
            className="w-full py-3 bg-gray-800 text-white font-bold rounded-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-800"
          >
            Add Product
          </button>
        </form>
      </div>
    </div>
  );
}

export default AddProduct;
