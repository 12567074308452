import React from 'react';
import { NavLink } from 'react-router-dom';

const Sidebar = () => {
  return (
    <aside className="bg-gray-800 text-white w-64 h-screen flex flex-col py-6 px-4">
      <div className="mb-10">
        <NavLink 
          to="/" 
          className={({ isActive }) => 
            isActive ? "bg-gray-700 text-xl font-semibold py-3 px-5 rounded mb-6 block" : "text-xl font-semibold py-3 px-5 rounded hover:bg-gray-700 mb-6 block"
          }
        >
          Dashboard
        </NavLink>
        <NavLink 
          to="/businessDash" 
          className={({ isActive }) => 
            isActive ? "bg-gray-700 text-xl font-semibold py-3 px-5 rounded mb-6 block" : "text-xl font-semibold py-3 px-5 rounded hover:bg-gray-700 mb-6 block"
          }
        >
          Business Dashboard
        </NavLink>
      </div>
      
      <nav className="flex flex-col space-y-3">
        <NavLink 
          to="/orders" 
          className={({ isActive }) => 
            isActive ? "bg-gray-700 py-2 px-5 rounded" : "py-2 px-5 rounded hover:bg-gray-700"
          }
        >
          Orders
        </NavLink>

        <NavLink 
          to="/addproduct" 
          className={({ isActive }) => 
            isActive ? "bg-gray-700 py-2 px-5 rounded" : "py-2 px-5 rounded hover:bg-gray-700"
          }
        >
          Add Product
        </NavLink>

        <NavLink 
          to="/categories" 
          className={({ isActive }) => 
            isActive ? "bg-gray-700 py-2 px-5 rounded" : "py-2 px-5 rounded hover:bg-gray-700"
          }
        >
          Categories
        </NavLink>

        <NavLink 
          to="/dieshapes" 
          className={({ isActive }) => 
            isActive ? "bg-gray-700 py-2 px-5 rounded" : "py-2 px-5 rounded hover:bg-gray-700"
          }
        >
          Die Shapes
        </NavLink>

        <NavLink 
          to="/users" 
          className={({ isActive }) => 
            isActive ? "bg-gray-700 py-2 px-5 rounded" : "py-2 px-5 rounded hover:bg-gray-700"
          }
        >
          Users
        </NavLink>

      </nav>
    </aside>
  );
};

export default Sidebar;
