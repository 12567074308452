import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Categories = () => {
  const [categories, setCategories] = useState([]);
  const [newCategoryName, setNewCategoryName] = useState('');
  const [newCategoryImage, setNewCategoryImage] = useState(null);
  const [subCategories, setSubCategories] = useState({});
  const [newSubCategoryName, setNewSubCategoryName] = useState('');
  const [newSubCategoryImage, setNewSubCategoryImage] = useState(null);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get('https://api.payasvinimilk.com/api/categories/getCategories');
        setCategories(response.data);
        // Initialize subCategories state
        const subCategoriesMap = response.data.reduce((acc, category) => {
          acc[category._id] = category.subcategory || [];
          return acc;
        }, {});
        setSubCategories(subCategoriesMap);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, []);

  const handleAddCategory = async (e) => {
    e.preventDefault();
    if (newCategoryName.trim() && newCategoryImage) {
      const formData = new FormData();
      formData.append('name', newCategoryName.trim());
      formData.append('image', newCategoryImage);

      try {
        const response = await axios.post('https://api.payasvinimilk.com/api/categories/createCategory', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        setCategories([...categories, response.data]);
        setSubCategories({
          ...subCategories,
          [response.data._id]: [],
        });
        setNewCategoryName('');
        setNewCategoryImage(null);
      } catch (error) {
        console.error('Error adding category:', error);
      }
    }
  };

  const handleDeleteCategory = async (categoryId) => {
    try {
      await axios.delete(`https://api.payasvinimilk.com/api/categories/deleteCategory/${categoryId}`);
      setCategories(categories.filter((category) => category._id !== categoryId));
      setSubCategories((prev) => {
        const updated = { ...prev };
        delete updated[categoryId];
        return updated;
      });
    } catch (error) {
      console.error('Error deleting category:', error);
    }
  };

  const handleAddSubCategory = async (e) => {
    e.preventDefault();
    if (newSubCategoryName.trim() && newSubCategoryImage && selectedCategoryId) {
      const formData = new FormData();
      formData.append('name', newSubCategoryName.trim());
      formData.append('imgPath', newSubCategoryImage);

      try {
        const response = await axios.post(`https://api.payasvinimilk.com/api/categories/addSubCategory/${selectedCategoryId}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        setSubCategories({
          ...subCategories,
          [selectedCategoryId]: [...(subCategories[selectedCategoryId] || []), response.data],
        });
        setNewSubCategoryName('');
        setNewSubCategoryImage(null);
        window.location.reload();
      } catch (error) {
        console.error('Error adding subcategory:', error);
      }
    }
  };

  const handleImageChange = (e) => {
    setNewCategoryImage(e.target.files[0]);
  };

  const handleSubCategoryImageChange = (e) => {
    setNewSubCategoryImage(e.target.files[0]);
  };

  return (
    <div className="max-w-7xl mx-auto p-8 border border-gray-300 rounded-lg bg-white shadow-lg">
      <h1 className="text-4xl font-bold text-gray-800 mb-6">Categories and Subcategories</h1>

      {/* Add Category Form */}
      <form onSubmit={handleAddCategory} className="mb-10 p-6 bg-gray-100 rounded-lg shadow-sm">
        <h2 className="text-2xl font-semibold text-gray-700 mb-4">Add a New Category</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label className="block text-gray-600 mb-2">Category Name</label>
            <input
              type="text"
              value={newCategoryName}
              onChange={(e) => setNewCategoryName(e.target.value)}
              placeholder="Enter category name"
              className="border border-gray-300 p-3 rounded-lg w-full text-gray-900 focus:ring-2 focus:ring-blue-500 focus:outline-none"
            />
          </div>
          <div>
            <label className="block text-gray-600 mb-2">Category Image</label>
            <input
              type="file"
              onChange={handleImageChange}
              className="border border-gray-300 p-3 rounded-lg w-full"
            />
          </div>
        </div>
        <button
          type="submit"
          className="mt-4 bg-blue-600 text-white px-5 py-3 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
        >
          Add Category
        </button>
      </form>

      {/* Existing Categories List */}
      <div>
        {categories.length > 0 ? (
          categories.map((category) => (
            <div key={category._id} className="mb-8 p-6 bg-gray-100 rounded-lg shadow-sm">
              <div className="flex justify-between items-center">
                <div className="flex items-center space-x-4">
                  <img
                    src={category.image}
                    alt={category.name}
                    className="w-16 h-16 object-cover rounded-lg"
                  />
                  <h3 className="text-2xl font-semibold text-gray-800">{category.name}</h3>
                </div>
                <button
                  onClick={() => handleDeleteCategory(category._id)}
                  className="text-red-600 hover:text-red-800 focus:outline-none"
                >
                  Delete
                </button>
              </div>

              {/* Subcategories Section */}
              <div className="mt-6">
                <h4 className="text-xl font-semibold text-gray-700 mb-4">Subcategories</h4>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                  {(subCategories[category._id] || []).map((subCategory) => (
                    <div key={subCategory._id} className="flex items-center space-x-4">
                      <img
                        src={subCategory.imgPath}
                        alt={subCategory.name}
                        className="w-12 h-12 object-cover rounded-lg"
                      />
                      <p className="text-lg text-gray-700">{subCategory.name}</p>
                    </div>
                  ))}
                </div>
              </div>

              {/* Add Subcategory Form */}
              <form
                onSubmit={handleAddSubCategory}
                className="mt-6 p-4 bg-white rounded-lg shadow-inner"
                onClick={() => setSelectedCategoryId(category._id)}
              >
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <label className="block text-gray-600 mb-2">Subcategory Name</label>
                    <input
                      type="text"
                      value={newSubCategoryName}
                      onChange={(e) => setNewSubCategoryName(e.target.value)}
                      placeholder="Enter subcategory name"
                      className="border border-gray-300 p-3 rounded-lg w-full text-gray-900 focus:ring-2 focus:ring-blue-500 focus:outline-none"
                    />
                  </div>
                  <div>
                    <label className="block text-gray-600 mb-2">Subcategory Image</label>
                    <input
                      type="file"
                      onChange={handleSubCategoryImageChange}
                      className="border border-gray-300 p-3 rounded-lg w-full"
                    />
                  </div>
                </div>
                <button
                  type="submit"
                  className="mt-4 bg-green-600 text-white px-5 py-3 rounded-lg hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
                >
                  Add Subcategory
                </button>
              </form>
            </div>
          ))
        ) : (
          <p className="text-gray-500">No categories available</p>
        )}
      </div>
    </div>
  );
};

export default Categories;
