import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get('https://api.payasvinimilk.com/api/orders');
        console.log('API response:', response.data);
        if (response.data && Array.isArray(response.data.orders)) {
          setOrders(response.data.orders);
          console.log('Orders:', response.data.orders);
        } else {
          console.error('Unexpected data format:', response.data);
          setOrders([]);
        }
      } catch (error) {
        console.error('Error fetching orders:', error);
        setOrders([]);
      }
    };

    fetchOrders();
  }, []);

  const handleViewOrder = (orderId) => {
    navigate(`/order/${orderId}`);
  };

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-4">Orders</h1>

      {/* Orders Table */}
      <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-md">
        <thead>
          <tr className="bg-gray-100 border-b">
            <th className="py-2 px-4 text-left text-gray-600">Order ID</th>
            <th className="py-2 px-4 text-left text-gray-600">Date</th>
            <th className="py-2 px-4 text-left text-gray-600">Status</th>
            <th className="py-2 px-4 text-left text-gray-600">Customer</th>
            <th className="py-2 px-4 text-left text-gray-600">Total</th>
            <th className="py-2 px-4 text-left text-gray-600">Actions</th>
          </tr>
        </thead>
        <tbody>
          {orders.length > 0 ? (
            orders.map(order => (
              <tr key={order._id} className="border-b">
                <td className="py-2 px-4">{order._id}</td>
                <td className="py-2 px-4">
                  {order.orderDate 
                    ? new Date(order.orderDate).toISOString().split('T')[0] 
                    : 'N/A'
                  }
                </td>
                <td className="py-2 px-4">{order.status}</td>
                <td className="py-2 px-4">{order.userDetails?.name || 'Unknown'}</td>
                <td className="py-2 px-4">{order.totalPrice}</td>
                <td className="py-2 px-4">
                  <button
                    onClick={() => handleViewOrder(order._id)}
                    className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600"
                  >
                    View
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6" className="py-2 px-4 text-center text-gray-500">No orders found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Orders;
