import React, { useState, useEffect } from 'react';
import axios from 'axios';

const DieShapes = () => {
  const [dieShapes, setDieShapes] = useState([]);
  const [newDieShape, setNewDieShape] = useState('');

  useEffect(() => {
    fetchDieShapes();
  }, []);

  const fetchDieShapes = async () => {
    try {
      const response = await axios.get('https://api.payasvinimilk.com/api/dieshapes');
      setDieShapes(response.data);
    } catch (error) {
      console.error('Error fetching die shapes:', error);
    }
  };

  const addDieShape = async () => {
    if (!newDieShape.trim()) return alert('Shape name is required');
    
    try {
      const response = await axios.post('https://api.payasvinimilk.com/api/dieshapes', { name: newDieShape });
      setDieShapes([...dieShapes, response.data]);
      setNewDieShape('');
    } catch (error) {
      console.error('Error adding die shape:', error);
    }
  };

  const deleteDieShape = async (id) => {
    try {
      await axios.delete(`https://api.payasvinimilk.com/api/dieshapes/${id}`);
      setDieShapes(dieShapes.filter((shape) => shape._id !== id));
    } catch (error) {
      console.error('Error deleting die shape:', error);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="max-w-full mx-auto bg-white shadow-lg rounded-lg p-12 px-12">
        <h1 className="text-3xl font-bold text-gray-800 mb-8 text-center">Manage Die Shapes</h1>

        {/* Form to Add a New Die Shape */}
        <div className="mb-6">
          <h2 className="text-xl font-semibold text-gray-700 mb-4">Add New Die Shape</h2>
          <div className="flex items-center">
            <input
              type="text"
              placeholder="Enter die shape name"
              value={newDieShape}
              onChange={(e) => setNewDieShape(e.target.value)}
              className="flex-grow border border-gray-300 rounded-md px-4 py-2 mr-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <button
              onClick={addDieShape}
              className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition-colors duration-200"
            >
              Add Die Shape
            </button>
          </div>
        </div>

        {/* List of Die Shapes */}
        <div>
          <h2 className="text-xl font-semibold text-gray-700 mb-4">Existing Die Shapes</h2>
          {dieShapes.length === 0 ? (
            <p className="text-gray-500">No die shapes available.</p>
          ) : (
            <ul className="space-y-4">
              {dieShapes.map((shape) => (
                <li
                  key={shape._id}
                  className="flex justify-between items-center bg-gray-50 border border-gray-200 rounded-md px-4 py-3"
                >
                  <div>
                    <span className="font-semibold text-gray-700">ID:</span> {shape._id} <br />
                    <span className="font-semibold text-gray-700">Name:</span> {shape.name}
                  </div>
                  <button
                    onClick={() => deleteDieShape(shape._id)}
                    className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 transition-colors duration-200"
                  >
                    Delete
                  </button>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default DieShapes;
